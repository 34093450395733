<template>
    <Layout :pagetitle="DashBoard">
        <b-row>
            <b-col md="8">
                <StatisticsDashboard />   
            </b-col>
            <b-col md="4">
                <TopBuyers />
            </b-col>       
        </b-row>
        <b-modal
            v-model="showModal"
            class="v-modal-custom"
            size="md"
            title="AVISO DESATIVAÇÃO DE PAINEL"
            no-close-on-backdrop
        >
            <p>
                Este Painel será <strong>DESATIVADO</strong> no dia <strong>29/02/2024</strong>!
            </p>
            <p>
                Clique no link abaixo para ser redirecionado ao novo painel ou para falar com o suporte:
            </p>
            <b-col>
                <a class="bgn btn-link" target="_blank" :href="whatsappLink('Claudio Filho', '4892043819')">
                    <i class="mdi mdi-whatsapp"></i>
                    Contato de Suporte
                </a>
            </b-col>
            <br />
            <b-col v-if="myAccount.domains">
                <a class="bgn btn-link" target="_blank" :href="myAccount.domains[0] + '/backoffice'">
                    <i class="mdi mdi-table-arrow-right"></i>
                    Novo Painel
                </a>
                (Utilize as mesmas credenciais de acesso!)
            </b-col>
        </b-modal>
    </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import StatisticsDashboard from "../../../components/dashboard/StatisticsDashboard.vue";
import TopBuyers from "../../../components/dashboard/TopBuyers.vue"
import { ref, onMounted } from 'vue'
import { getProfile } from "../my-account/api";
import { Toasts } from "../../../utils/toast-alerts";

export default {
    components: {
        Layout,
        StatisticsDashboard,
        TopBuyers
    },
    setup()  {
        const showModal = ref(true);
        const myAccount = ref({
            name: "",
            whatsapp: "",
            email: "",
            username: "",
            password: "",
            password_confirmation: "",
        });

        onMounted(() => {
            getMyAccount();
        });

        async function getMyAccount(){
            try {
                const response = await getProfile();
                myAccount.value = response.user;

            }catch (error) {
                Toasts('error', 'Falha ao carregar')
            }
        }
 
        return {
            showModal,
            myAccount
        };
    }
};
</script>
