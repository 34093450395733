import { axios } from '@/axios'

export const getDashboard = (params = {}) => {
  return axios.get('/seller/dashboard', { params }).then(({ data }) => data)
}

export const getContests = (params = {}) => {
  return axios.get('/seller/contests', { params }).then(({ data }) => data)
}

export const checkWizard = () => {
  return axios.get('/seller/account/wizard').then(({ data }) => data)
}

export const dashboard = getDashboard