<template>
    <b-card title="Visão geral">
        <b-card-body>
            <b-row class="mb-3">
                <b-col>
                    <SelectContest 
                        placeholder="Pesquisar por sorteio"
                        v-model="contestId"
                    />
                </b-col>
                <b-col>
                    <VueDatePicker
                        v-model="period"
                        range
                        auto-apply
                        clearable
                        close-on-scroll
                        format="dd/MM/yyyy"
                        :enable-time-picker="false"
                        locale="pt"
                        model-type="yyyy-MM-dd"
                        dark
                    />
                </b-col>
                <b-col>
                    <b-dropdown variant="link" toggle-class="text-reset">
                        <template v-slot:button-content>
                        <b>
                            Classificar por: 
                        </b>
                            {{ groupByOptions[groupBy] }}
                        <b-icon icon="arrow-down-short"></b-icon>                       
                        </template>
                        <b-dropdown-item
                            v-for="(label, value) in groupByOptions"
                            :key="value"
                            @click="groupBy = value"
                        >
                            {{ label }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <!-- <b-col md="3" class="pt-2">
                    <div class="dropdown">
                        <a
                            class="dropdown-toggle text-reset"
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <b>Classificar por: </b>
                            {{ groupByOptions[groupBy] }}
                            <b-icon icon="arrow-down-short"></b-icon>
                        </a>

                        <div class="dropdown-menu dropdown-menu-end">
                            <a
                            v-for="(label, value) in groupByOptions"
                            :key="value"
                            class="dropdown-item"
                            @click="groupBy = value"
                            > {{ label }} </a>
                        </div>
                    </div>
                </b-col> -->
            </b-row>

            <b-row class="gy-4">
                <b-col xxl="3">
                    <b-row class="mt-2 mb-2">
                        <p class="mb-1">
                        Faturamento no período
                        </p>
                        <b-col class="d-flex flex-wrap align-items-center gap-2">
                            <h2 class="mb-0">
                                {{ $n(totalBilled, 'currency') }}
                            </h2>
                            <!-- <div
                                class="badge rounded-pill font-size-13"
                                :class="rate <= 0 ? 'badge-soft-danger': 'badge-soft-success'"
                            >
                                {{ rate }}%
                            </div> -->
                        </b-col>
                    </b-row>
                    <b-row class="g-0">
                        <b-col sm="6">
                            <b-col class="text-center">
                                <p>Pedidos</p>
                                <h5> {{ totalOrders }} </h5>
                            </b-col>
                        </b-col>
                        <b-col sm="6">
                            <b-col class="text-center">
                                <p>Clientes</p>
                                <h5>{{ totalCustomers }}</h5>
                            </b-col>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xxl="9">
                    <b-col>
                        <apexchart
                            v-if="isPageMounted && !loading"
                            height="350"
                            type="bar"
                            :options="overviewChartOptions"
                            :series="overviewSeries"
                        >
                        </apexchart>
                        <b-col v-else class="text-center my-5">
                            <b-spinner class="align-middle" />
                        </b-col>
                    </b-col>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import i18n from '@/i18n'
import { onMounted, ref, watch } from 'vue'
import { dashboard } from '../../services/dashboard/dashboard-api'
import dayjs from 'dayjs'
import SelectContest from '@/components/select-contest'

const defaultOverviewChartOptions = {
  chart: {
    type: "bar",
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "35%",
      borderRadius: 6,
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  colors: ["#fff", "#fff"],
  xaxis: {
    categories: [],
  },
  yaxis: {
    labels: {
      formatter: function (value, { seriesIndex }) {
        if (seriesIndex === 1) {
            return i18n.global.n(value, 'currency')
        }
        return value
      },
    },
    tickAmount: 4,
  },
  legend: {
    show: false,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 1,
      inverseColors: true,
      gradientToColors: ["#f1f3f7", "#3b76e1"],
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 38, 100, 38],
    },
  },
}

const groupByOptions = {
    day: 'Dia',
    week: 'Semana',
    month: 'Mês',
    year: 'Ano',
}

export default {
  components: {
    SelectContest
  },
  setup() {
    const totalBilled = ref(0)
    const totalOrders = ref(0)
    const totalCustomers = ref(0)
    const overviewSeries = ref([])
    const overviewChartOptions = ref(defaultOverviewChartOptions)
    const groupBy = ref('day')
    const rate = ref(0)
    const contestId = ref(null)
    const now = dayjs().format('YYYY-MM-DD')
    const period = ref([now, now])
    const loading = ref(false)
    const isPageMounted = ref(false)

    const fetchData = () => {
        loading.value = true
        let perioder = []
        
        if (period.value) {
            perioder.push(period.value[0])
            perioder.push(dayjs(period.value[1]).add(23, 'hour').add(59, 'minute').format('YYYY-MM-DD HH:mm'))
        }
        dashboard({ billing: {
            period: perioder,
            group_by: groupBy.value,
            contest_id: contestId?.value?.value
            // previous: true
        } }).then((data) => {
            loading.value = false
            const { current, previous } = data.billing
            const counts = current.map(({ count }) => count)
            const amounts = current.map(({ amount }) => parseFloat(amount))
            const categories = current.map(({ date }) => {
                return date.split('-').reverse().join('/')
            })
  
            overviewChartOptions.value = {
            ...defaultOverviewChartOptions,
            ...{ xaxis: {
                categories
                }
            }
            }
    
            totalBilled.value = amounts.reduce((a, c) => a+=c, 0)
            totalOrders.value = counts.reduce((a, c) => a+=c, 0)
            totalCustomers.value = current.reduce((a, c) => a+=c.customers, 0)
    
            overviewSeries.value = [
                { name: 'Pedidos', data: counts },
                { name: 'Valor', data: amounts }
            ]

            const previousAmount = previous.reduce((a, c) => a+=parseFloat(c.amount), 0)

            rate.value = (((totalBilled.value / previousAmount) * 100) - 100).toFixed(2)

            if (isNaN(rate.value)) {
                rate.value = 0
            }
      })
    }

    watch([
        groupBy,
        period,
        contestId
    ], () => {
        fetchData()
    })

    onMounted(() => {
        isPageMounted.value = true;
        fetchData()
    })

    return {
        overviewChartOptions,
        overviewSeries,
        totalBilled,
        totalOrders,
        totalCustomers,
        groupByOptions,
        groupBy,
        rate,
        contestId,
        period,
        loading,
        isPageMounted
    }
  }
}
</script>
