<template>
    <b-row class="row">
        <b-col>
            <b-card>
                <b-card-body>
                    <b-card-title class="mb-3">
                        Top Compradores
                    </b-card-title>
                    <b-col>
                        <SelectContest
                            v-model="topContest"
                            class="mb-3"
                        />
                    </b-col>
                    <b-col>
                        <VueDatePicker
                            v-model="period"
                            range
                            auto-apply
                            clearable
                            close-on-scroll
                            format="dd/MM/yyyy"
                            :enable-time-picker="false"
                            locale="pt"
                            model-type="yyyy-MM-dd"
                            dark
                        />
                    </b-col>

                    <SimpleBar data-simplebar style="height: 45vh;">
                        <ul class="list-unstyled mb-0" v-if="(topBuyers && topBuyers.length > 0)">
                            <li
                                class="mx-3 my-3"
                                v-for="(item, index) of topBuyers"
                                :key="index"
                            >
                                <b-col class="d-flex align-items-center">
                                    <b-row class="d-flex me-3">
                                        <b-avatar variant="primary" rounded="xl" >
                                            #{{ (index + 1) }}   
                                        </b-avatar>
                                    </b-row>
                                    <b-row>
                                        <p class="mb-1">
                                            {{ item.customer_name }}
                                        </p>
                                        <b-col>
                                            <b>{{ $n(parseFloat(item.amount), 'currency') }}</b>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </li>
                        </ul>
                        <ul class="list-unstyled mb-0" v-else-if="!loading">
                            <li class="px-4 py-3">
                                <b-col class="d-flex align-items-center">
                                    <b-alert v-if="isFeatureEnabled('buyers-ranking')" show variant="warning" class="text-center mt-3">
                                        <b>Sem compradores neste sorteio</b>
                                    </b-alert>
                                    <b-col v-else>
                                        <b-card                                               
                                            header="Ranking de compradores! Utilize estes dados para:"
                                            header-tag="header"
                                            border-variant="success"
                                        >
                                            <b-card-text>
                                                <ul>
                                                    <li><b>Acompanhar</b></li>
                                                    <li><b>Engajar</b></li>
                                                    <li><b>Premiar</b></li>
                                                </ul>
                                            </b-card-text>
                                            <upgrade-trigger
                                                title="Clique aqui!"
                                                class="btn btn-outline-success btn-block mt-3"
                                                style="width: 100%"
                                            />
                                        </b-card>
                                    </b-col>
                                </b-col>
                            </li>
                        </ul>
                        <b-col v-else class="text-center my-5">
                            <b-spinner class="align-middle" />
                        </b-col>
                    </SimpleBar>
                </b-card-body>
            </b-card>
        </b-col>
        
    </b-row>
</template>

<script>
import appConfig from "@/app.config";
import { SimpleBar } from "simplebar-vue3";
import { getDashboard } from "../../services/dashboard/dashboard-api";
import store from '@/state/store'
import SelectContest from '@/components/select-contest'
import storage from '@/storage'
import { checkWizard } from '../../services/dashboard/dashboard-api'
import dayjs from 'dayjs'

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    SimpleBar,
    SelectContest,
  },
  data() {
    return {
      pagetitle: "Dashboard",

      chartOptions: {
        chart: {
          type: "radialBar",
          height: 162,
          sparkline: {
            enabled: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#f3f2f9",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: false,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2,
              },
            },
            hollow: {
              margin: 15,
              size: "65%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "22px",
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        colors: ["#3b76e1"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91],
          },
        },
        labels: ["Average Results"],
      },
      series: [76],
      overviewChartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "35%",
            borderRadius: 6,
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        colors: ["#fff", "#fff"],
        xaxis: {
          categories: [
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
          ],
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + "k";
            },
          },
          tickAmount: 4,
        },
        legend: {
          show: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 1,
            inverseColors: true,
            gradientToColors: ["#f1f3f7", "#3b76e1"],
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 38, 100, 38],
          },
        },
      },
      overviewSeries: [
        {
          name: "Net Profit",
          data: [18, 21, 17, 24, 21, 27, 25, 32, 26],
        },
        {
          name: "Revenue",
          data: [21, 24, 20, 27, 25, 29, 26, 34, 30],
        },
      ],
      orderSeries: [70, 25, 15],
      orderChartOptions: {
        chart: {
          height: 220,
          type: "donut",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: ["Completed", "Pending", "Cancel"],
        colors: ["#3b76e1", "#f1f3f7", "#f56e6e"],
        fill: {
          type: "gradient",
        },
        legend: {
          show: false,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
        },
      },
      activitySeries: [
        {
          name: "Current",
          data: [21, 54, 45, 84, 48, 56],
        },
        {
          name: "Previous",
          data: [40, 32, 60, 32, 55, 45],
        },
      ],
      activityChartOptions: {
        chart: {
          height: 270,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        colors: ["#3b76e1", "#f56e6e"],
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "right",
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.45,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        yaxis: {
          tickAmount: 4,
        },
        xaxis: {
          categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        },
      },
      /// APP
      topBuyers: [],
      now: dayjs().format('YYYY-MM-DD'),
      period: [],
      contests: [],
      topContest: null,
      loading: false
    };
  },
  methods: {
    fetchDashboard(){
      this.loading = true
      let perioder = []

      if (this.period) {
        perioder.push(this.period[0])
        perioder.push(dayjs(this.period[1]).add(23, 'hour').add(59, 'minute').format('YYYY-MM-DD HH:mm'))
      }

      getDashboard({
        top_buyers: {
          contest_id: this?.topContest?.value,
          period: perioder,
        },
      }).then((data) => {
        this.topBuyers = data.top_buyers
      }).finally(() => {
        this.loading = false
      })
    }
  },
  watch: {
    topContest() {
      if (this.isFeatureEnabled('buyers-ranking')) {
        this.fetchDashboard()
      } else {
        store.dispatch('app/showUpgradeScreen')
      }
    },
    period() {
      if (this.isFeatureEnabled('buyers-ranking')) {
        this.fetchDashboard()
      } else {
        store.dispatch('app/showUpgradeScreen')
      }
    }
  },
  mounted() {
    /**
     * Evita requisições constantes para checagem de wizard
     * Requisições a cada 10min (600000ms)
     */
    this.period = [this.now, this.now]
    const cacheTime = 0 // 600000 * 0.25
    const lastCheck = storage.getItem('wizard_checked_at') ?? Date.now()
    if (Date.now() - lastCheck >= cacheTime) {
      checkWizard().then((data) => {
        storage.set('wizard_checked_at', Date.now())
        const keys = Object.keys(data)
        if (Array.isArray(keys) && keys.length > 0) {
          this.$router.push({
            name: 'wizard_stages',
            params: {
              stage: keys[0]
            }
          })
        }
      })
    } else {
      console.log('no_fetch')
    }
  }
};
</script>
